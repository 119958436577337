import './index.scss'


// 表单点击提示文字上移
$('.footer .form input').focus(function () {
    if (!$(this).parents('.form-item').hasClass('active')) {
        $(this).parents('.form-item').addClass('active')
    }
}).blur(function () {
    if ($(this).val() == '') {
        $(this).parents('.form-item').removeClass('active')
    }
})

// 返回顶部
$('.footer .backtop').on('click',function(){
  $("html, body").animate({
    scrollTop: 0
  }, {
      duration: 500,
      easing: "swing"
  });
  return false;
})