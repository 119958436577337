import './index.scss'

$(document).scroll(function () { 
    var scroH = $(document).scrollTop();  
    var viewH = $(window).height();        
    var contentH = $(document).height();       
    if(scroH > 205){  
      $('header').addClass('scroll-header')
    }else{
      $('header').removeClass('scroll-header')
    }            
});

//侧栏菜单隐藏显示
$('[data-open="cmenu"]').on('click', function () {
  if ($('.header .hamburger').hasClass('active')) {
    $('.header .hamburger').removeClass('active')
    $('header .mob-nav-mask').fadeOut(300)
    $('header .mob-nav').removeClass('active')
  } else {
    $('.header .hamburger').addClass('active')
    $('header .mob-nav-mask').fadeIn(300)
    $('header .mob-nav').addClass('active')
  }
})
$('header .mob-nav-mask').on('click', function () {
  $('.header .hamburger').removeClass('active')
  $('header .mob-nav-mask').fadeOut(300)
  $('header .mob-nav').removeClass('active')
})