module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n  <div class="header-zhanwei"></div>\r\n  <div class="header">\r\n    <div class="left">\r\n      <a href="index.html">\r\n        <img class="green-logo" src="' +
((__t = (require('../../assets/images/logo_green.png'))) == null ? '' : __t) +
'" alt="">\r\n        <img class="white-logo" src="' +
((__t = (require('../../assets/images/logo_white.png'))) == null ? '' : __t) +
'" alt="">\r\n      </a>\r\n    </div>\r\n    <div class="right">\r\n      <a href="index.html" class="active">Home</a>\r\n      <a href="about_profile.html">About us</a>\r\n      <a href="list_shop_diagnostic.html">Products & Solutions</a>\r\n      <a href="list_article.html">News</a>\r\n      <a href="service.html">Service & Support</a>\r\n      <a href="contact.html">Contact</a>\r\n      <!-- <a href="#" class="language"><i class="iconfont icon-diqiu"></i> Region/Language</a> -->\r\n      <div class="language">\r\n        <div class="title"><i class="iconfont icon-diqiu"></i> Region/Language</div>\r\n        <div class="sub">\r\n          <a href="http://www.hisense-b2b.com/">Europe</a>\r\n          <a href="https://hisense.co.za/">South Africa</a>\r\n          <a href="https://www.hisense-usa.com/">US</a>\r\n          <a href="https://med.hisense.com/">China</a>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class="open-menu" data-open="cmenu">\r\n      <div class="hamburger hamburger-11">\r\n          <div></div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class="mob-nav-mask"></div>\r\n  <div class="mob-nav">\r\n    <a href="index.html" class="active">Home</a>\r\n    <a href="about_profile.html">About us</a>\r\n    <a href="list_shop_diagnostic.html">Products & Solutions</a>\r\n    <a href="list_article.html">News</a>\r\n    <a href="service.html">Service & Support</a>\r\n    <a href="contact.html">Contact</a>\r\n    <div class="language">\r\n      <div class="title"><i class="iconfont icon-diqiu"></i> Region/Language</div>\r\n      <div class="sub">\r\n        <a href="http://www.hisense-b2b.com/">Europe</a>\r\n        <a href="https://hisense.co.za/">South Africa</a>\r\n        <a href="https://www.hisense-usa.com/">US</a>\r\n        <a href="https://med.hisense.com/">China</a>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</header>';

}
return __p
}