
import {WOW} from '../../../node_modules/wowjs/dist/wow';
// import './jquery.nicescroll.min'

// if (window.devicePixelRatio < 2) {
//   if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
//     $('body').niceScroll({
//       mousescrollstep: 40,
//       scrollspeed: 80,
//       zindex: 101,
//       cursorcolor: '#BDBDBD',
//       cursorborder: '1px solid #BDBDBD',
//       cursorwidth: '5px',
//       cursoropacitymax: 0.6,
//       smoothscroll: true,
//       iframeautoresizeL: true,
//     });
//   }
// }
// function resizeNiceScroll () {
//   if (window.devicePixelRatio < 2) {
//       if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
//           setTimeout(() => {
//               $('body').getNiceScroll().resize()
//           }, 500);
//       }
//   }
// }

// new WOW().init();
var wow = new WOW({
  offset: 50,
});
wow.init();



// 表单点击提示文字上移
$('.pro-common-form input').focus(function () {
  if (!$(this).parents('.form-item').hasClass('active')) {
      $(this).parents('.form-item').addClass('active')
  }
}).blur(function () {
  if ($(this).val() == '') {
      $(this).parents('.form-item').removeClass('active')
  }
})